/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ColorInput, SimpleGrid, Text } from '@mantine/core'
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone'
import { IconCloudUpload } from '@tabler/icons-react'
import { formData } from '../../utils/interface';

interface BrandConfigurationProps {
    formData: formData;
    handleChange: (name: string, value: string) => void;
    handleInputChange: (event: any) => void;
    handleFileChange: (file: File, name: string ) => void;
}

const BrandConfiguration = (props: BrandConfigurationProps) => {
    const { formData, handleChange, handleFileChange } = props;
  return (
    <div className="tw-max-w-3xl tw-mt-5 brand_config">
        <div className="tw-space-y-4">
            <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1" htmlFor="name">
                        Domain Name
                    </label>
                    <input
                    className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-bg-[#F9FAFB] tw-rounded-lg tw-outline-none tw-focus:ring-2 tw-focus:ring-blue-500"
                    type="text"
                    id="domainName"
                    name="domainName"
                    value={formData.domainName}
                    onChange={(e) => handleChange('domainName', e.target.value)}
                    placeholder="Domain Name"
                    />
                </div>
                <div className="tw-w-full md:tw-w-1/2 tw-px-2">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1" htmlFor="title">
                        Title
                    </label>
                    <input
                    className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-bg-[#F9FAFB] tw-rounded-lg tw-outline-none tw-focus:ring-2 tw-focus:ring-blue-500"
                    type="text"
                    id="title"
                    name="title"
                    value={formData.title}
                    onChange={(e) => handleChange('title', e.target.value)}
                    placeholder="Title"
                    />
                </div>
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1" htmlFor="backgroundColor">
                        Background Color
                    </label>
                    <ColorInput
                        name="backgroundColor"
                        value={formData.backgroundColor}
                        onChange={(e) => handleChange('backgroundColor', e)}
                        placeholder="Background Color"
                    />
                </div>
                <div className="tw-w-full md:tw-w-1/2 tw-px-2">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1" htmlFor="textColor">
                        Text Color
                    </label>
                    <ColorInput
                        name="textColor"
                        value={formData.textColor}
                        onChange={(e) => handleChange('textColor', e)}
                        placeholder="Text Color"
                    />
                </div>
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1" htmlFor="defaultTextColor">
                        Default Text Color
                    </label>
                    <ColorInput
                        name="defaultTextColor"
                        value={formData.defaultTextColor}
                        onChange={(e) => handleChange('defaultTextColor', e)}
                        placeholder="Default Text Color"
                    />
                </div>
                <div className="tw-w-full md:tw-w-1/2 tw-px-2">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1" htmlFor="primaryColor">
                        Primary Color
                    </label>
                    <ColorInput
                        name="primaryColor"
                        value={formData.primaryColor}
                        onChange={(e) => handleChange('primaryColor', e)}
                        placeholder="Primary Color"
                    />
                </div>
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1" htmlFor="secondaryColor">
                        Secondary Color
                    </label>
                    <ColorInput
                        name="secondaryColor"
                        value={formData.secondaryColor}
                        onChange={(e) => handleChange('secondaryColor', e)}
                        placeholder="Secondary Color"
                    />
                </div>
                <div className="tw-w-full md:tw-w-1/2 tw-px-2">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1" htmlFor="sidebarColor">
                        Sidebar Color
                    </label>
                    <ColorInput
                        id="sidebarColor"
                        name="sidebarColor"
                        value={formData.sidebarColor}
                        onChange={(e) => handleChange('sidebarColor', e)}
                        placeholder="Sidebar Color"
                    />
                </div>
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1" htmlFor="buttonColor">
                        Button Color
                    </label>
                    <ColorInput
                        name="buttonColor"
                        value={formData.buttonColor}
                        onChange={(e) => handleChange('buttonColor', e)}
                        placeholder="Button Color"
                    />
                </div>
                <div className="tw-w-full md:tw-w-1/2 tw-px-2">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1" htmlFor="iconColor">
                        Icon Color
                    </label>
                    <ColorInput
                        name="iconColor"
                        value={formData.iconColor}
                        onChange={(e) => handleChange('iconColor', e)}
                        placeholder="Icon Color"
                    />
                </div>
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1" htmlFor="buttonColor">
                        Favicon Logo
                    </label>
                    <Dropzone accept={IMAGE_MIME_TYPE} 
                        onDrop={(files) => handleFileChange(files[0], 'brandFavicon')}>
                    <IconCloudUpload style={{ width: '25px', height: '25px', color: '#667085', display: 'block', margin: '10px auto' }} />
                    <Text fw={600} size='sm' c="#2AAAAF" ta="center">Click to upload</Text>
                    <Text size='sm' ta="center">or drag and drop image here</Text>
                </Dropzone>
                </div>
                <div className="tw-w-full md:tw-w-1/2 tw-px-2">
                    {formData.brandFavicon && 
                        <SimpleGrid cols={{ base: 1 }} mt={50}>
                            <img src={formData.brandFavicon} style={{ width: '50px', height: '50px', display: 'block', margin: 'auto' }} />
                        </SimpleGrid>
                    }
                </div>
            </div>
            <div className="tw-flex tw-flex-wrap tw-mb-4 ">
                <div className="tw-w-full md:tw-w-1/2 tw-px-2 tw-mb-4 md:tw-mb-0">
                    <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1" htmlFor="brandLogo">
                        Brand Logo
                    </label>
                    <Dropzone accept={IMAGE_MIME_TYPE} 
                        onDrop={(files) => handleFileChange(files[0], 'brandLogo')}>
                    <IconCloudUpload style={{ width: '25px', height: '25px', color: '#667085', display: 'block', margin: '10px auto' }} />
                    <Text fw={600} size='sm' c="#2AAAAF" ta="center">Click to upload</Text>
                    <Text size='sm' ta="center">or drag and drop image here</Text>
                </Dropzone>
                </div>
                <div className="tw-w-full md:tw-w-1/2 tw-px-2">
                    {formData.brandLogo && <SimpleGrid cols={{ base: 1 }} mt={25}>
                        <img src={formData.brandLogo} style={{ display: 'block', margin: 'auto' }} />
                    </SimpleGrid>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default BrandConfiguration
